
import { defineComponent } from "vue";
import { useAdminActivities } from "@/graphql/activity/admin-activities";
import { useAdjustExpiration } from "@/graphql/subscription/adjust-expiration";
export default defineComponent({
  name: "Activity",
  setup() {
    return {
      ...useAdminActivities(),
      ...useAdjustExpiration(),
    };
  },
});
