import { gql } from "@apollo/client";
import { useQuery, useResult } from "@vue/apollo-composable";
import { reactive, ref, watch } from "vue";
import {
  PaginationInput,
  QueryAdminActivitiesArgs,
  ResponsiblePagination,
} from "@/graphql/types";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { InitialPagination, PAGINATION_META } from "@/graphql/utils/pagination";
import { remainSubscriptionDuration } from "@/graphql/utils/utils";

type AdminActivitiesData = {
  adminActivities: ResponsiblePagination;
};

const ADMIN_ACTIVITIES = gql`
  query AdminActivities($input: PaginationInput!) {
    adminActivities(input:$input){
      items{
        activity{ 
          id 
          name 
          country 
          createdAt
          payments{ id expiredAt }
          revenue
        }
        user{ id name image }
      }
      ${PAGINATION_META}
    }
  }
`;
const FILTERS_ = {
  "activity.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  "activity.country": {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  "activity.createdAt": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  "activity.expiredAt": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  "activity.remain": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  "activity.revenue": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  "user.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
};

export const useAdminActivities = () => {
  const input = reactive<PaginationInput>({
    keyword: "",
    limit: 20,
    page: 1,
  });
  const durations = ref<number[]>([]);
  const filters = ref(FILTERS_);
  const { loading, result } = useQuery<
    AdminActivitiesData,
    QueryAdminActivitiesArgs
  >(ADMIN_ACTIVITIES, { input });
  const responsible = useResult<AdminActivitiesData, any, any>(
    result,
    InitialPagination,
    (res) => {
      return {
        meta: res.adminActivities.meta,
        items: res.adminActivities.items.map((respo) => {
          return {
            ...respo,
            activity: {
              ...respo.activity,
              createdAt: new Date(respo.activity.createdAt),
              expiredAt: respo.activity.payments.length
                ? new Date(respo.activity.payments[0].expiredAt)
                : null,
              remain: remainSubscriptionDuration(respo.activity),
            },
          };
        }),
      };
    }
  );
  watch(
    () => responsible.value.items,
    (items: any[]) => {
      durations.value = items.map((it) => it.activity.remain);
    },
    { immediate: true }
  );
  function clearFilter() {
    Object.assign(filters.value, FILTERS_);
  }
  return { loading, input, durations, filters, responsible, clearFilter };
};
