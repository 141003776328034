import { gql } from "@apollo/client";
import { useMutation } from "@vue/apollo-composable";
import {
  Activity,
  MutationAdjustExpirationArgs,
  AdjustExpirationInput,
} from "@/graphql/types";
import moment from "moment";
import { CONSTANTS } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";

type AdjustExpirationData = {
  adjustExpiration: Activity;
};

const MUTATION = gql`
  mutation AdjustExpiration($input: AdjustExpirationInput!) {
    adjustExpiration(input: $input) {
      id
      payments {
        id
        expiredAt
      }
    }
  }
`;

export const useAdjustExpiration = () => {
  const { loading, mutate, onDone } = useMutation<
    AdjustExpirationData,
    MutationAdjustExpirationArgs
  >(MUTATION);

  onDone(({ data }) => {
    const activity = data?.adjustExpiration;
    if (activity && activity.id === activeActivity.value.id) {
      activeActivity.value.payments = [...activity.payments];
      localStorage.setItem(
        CONSTANTS.activeActivity,
        JSON.stringify(activeActivity.value)
      );
    }
  });
  function adjustExpiration(newRemain: number, activity: any) {
    if (!isNaN(newRemain)) {
      const { id } = activity;
      const input: AdjustExpirationInput = {
        activityId: id,
        paymentId: null,
        expiredAt: null,
      };
      if (newRemain <= 0) newRemain -= 1;
      input.expiredAt = moment(Date.now())
        .startOf("days")
        .add(newRemain, "days")
        .toDate();
      void mutate({ input });
    }
  }
  return { loading, adjustExpiration };
};
